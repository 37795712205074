import Vue from "vue";

const cartStore = {
  state: {
    products: [],
  },
  mutations: {
    addProductToCart(state, product) {
      let foundedIndex = state.products.findIndex(p => p.id === product.id);
      if (foundedIndex >= 0) {
        Vue.set(state.products, foundedIndex, {...state.products[foundedIndex], count: state.products[foundedIndex].count + 1});
      }else {
        product.count = 1;
        state.products.push(product);
      }
    },
    removeFromCart(state, product) {
      let foundedIndex = state.products.findIndex(p => p.id === product.id);
      if (foundedIndex >= 0 && state.products[foundedIndex].count > 1) {
        Vue.set(state.products, foundedIndex, {...state.products[foundedIndex], count: state.products[foundedIndex].count - 1});
      }else {
        state.products = state.products.filter(p => p.id !== product.id);
      }
    }
  },
  actions: {
    addToCart({ commit }, payload) {
      commit("addProductToCart", payload);
    },
    removeFromCart({ commit }, payload) {
      commit('removeFromCart', payload);
    }
  },
  getters: {
    productsInCart(state) {
      return state.products;
    },
    cartCount(state) {
      return state.products.length;
    }
  },
};

export default cartStore;

import Vue from 'vue'
import Vuex from 'vuex'
import cartStore from "@/store/cart";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cartStore
  }
})

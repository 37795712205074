import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'productsList',
    components: {
      default: () => import('@/views/ProductsListView.vue'),
      drawer: () => import('@/components/global/TheDrawer.vue')
    }
  },
  {
    path: '/cart',
    name: 'cart',
    components: {
      default: () => import('@/views/CartView.vue'),
      drawer: () => import('@/components/global/TheDrawer.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import axios from "axios";

const axiosPlugin = {
    install(Vue) {
        Vue.prototype.$http = axios.create({
            baseURL: 'https://fakestoreapi.com',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};

export default axiosPlugin;
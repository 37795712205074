<template>
  <v-app id="app">
      <v-row>
          <v-app-bar>
              <v-row class="justify-end pr-6 pt-6">
                  <v-badge
                      v-if="cartCount"
                      class="mr-4"
                      color="green"
                      :content="cartCount"
                  >
                      Cart
                  </v-badge>
              </v-row>
          </v-app-bar>
      </v-row>
      <v-row class="mt-0 fill-height">
          <v-col :cols="2">
              <router-view name="drawer"/>
          </v-col>
          <v-col>
              <router-view/>
          </v-col>
      </v-row>
  </v-app>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters(['cartCount'])
    }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<script setup>
</script>